<template>
  <v-tooltip bottom max-width="200">
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        small
        v-bind="attrs"
        :disabled="isDisabled"
        :loading="dayVerificationStore.isLoading"
        :block="fullWidth"
        v-on="on"
        @click="$emit('verify-day')"
      >
        Mark as verified
        <v-icon class="ml-2">fa-check</v-icon>
      </v-btn>
    </template>
    <span>Note: this</span>
    <strong>will not have an effect on dashboard.</strong>
    <span>
      It's meant for the admin metrics. Once a day is marked as verified, it
      will be highlighted in
    </span>
    <strong class="green--text">green</strong>
    <span> on the calendar. </span>
  </v-tooltip>
</template>

<script>
import { useDayVerificationStore } from "@/stores/dayVerification"
import { mapStores } from "pinia"

export default {
  name: "MarkDayAsVerifiedButton",
  props: {
    selectedDate: {
      type: [String, Date],
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useDayVerificationStore),
    isDisabled() {
      const date = this.$moment(this.selectedDate).format("YYYY-MM-DD")

      return (
        this.dayVerificationStore.isLoading ||
        this.dayVerificationStore.isDayVerified(date)
      )
    },
  },
}
</script>
