<template>
  <ImpersonateUserButton
    :email="ownerEmail"
    :target-route="previewLink"
    :disabled-tooltip="true"
  >
    <template #custom-button="{ onClick }">
      <v-btn color="primary" dark small block target="_blank" @click="onClick">
        dashboard
        <v-icon class="ml-2">fa-external-link-alt</v-icon>
      </v-btn>
    </template>
  </ImpersonateUserButton>
</template>

<script>
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"

export default {
  name: "OpenInDashGateReportBtn",
  components: {
    ImpersonateUserButton,
  },
  props: {
    date: {
      type: [String, Date],
      default: "",
    },
  },
  computed: {
    ...mapStores(useGateReportStore),
    ownerEmail() {
      return this.gateReportStore.selectedProject?.owner?.email
    },
    previewLink() {
      return `/projects/${
        this.gateReportStore.selectedProject?.exid
      }/gate-report?selectedDate=${this.$moment(this.date).format(
        "YYYY-MM-DD"
      )}`
    },
  },
}
</script>
