<template>
  <portal to="hovered-thumbnails-container">
    <div
      v-if="isThumbnailsHovered"
      ref="hoveredThumbnail"
      class="d-flex position-absolute"
      :style="hoveredThumbnailStyle"
    >
      <div
        v-for="thumbnail in hoveredThumbnailUrls"
        :key="thumbnail.url"
        class="thumbnail-hover position-relative"
      >
        <v-img :src="thumbnail.url" @load="onLoadHoveredThumbnail" />
      </div>
    </div>
  </portal>
</template>

<script>
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"

export default {
  props: {
    hoveredThumbnail: {
      type: Object,
      default: () => ({ isHovered: false, url: false }),
    },
    displayPosition: {
      type: String,
      default: () => "top",
    },
  },
  data() {
    return {
      hoveredThumbnailLoaded: false,
    }
  },
  computed: {
    ...mapStores(useLayoutStore),
    hoveredThumbnailUrls() {
      if (this.hoveredThumbnail.isHovered) {
        return [{ url: this.hoveredThumbnail.url }]
      }

      return []
    },
    isThumbnailsHovered() {
      return this.hoveredThumbnail.isHovered && this.hoveredThumbnail.url
    },
    hoveredThumbnailStyle() {
      if (!this.canDisplayHoveredThumbnail) {
        return {}
      }

      return this.calculateHoveredThumbnailPosition()
    },
    canDisplayHoveredThumbnail() {
      return this.hoveredThumbnail.isHovered && this.hoveredThumbnailLoaded
    },
  },
  watch: {
    hoveredThumbnail({ isHovered }) {
      if (!isHovered) {
        this.hoveredThumbnailLoaded = false
      }
    },
  },
  methods: {
    onLoadHoveredThumbnail() {
      this.$nextTick(() => {
        this.hoveredThumbnailLoaded = true
      })
    },
    getSidebarAndToolbarSizes() {
      let sidebarWidth = "0px"
      let toolbarHeight = 45
      if (this.$vuetify.breakpoint.lgAndUp) {
        sidebarWidth = this.layoutStore.isMiniSidebar
          ? this.$scssVars.sidebarWidthSm
          : this.$scssVars.sidebarWidth
        toolbarHeight = 0
      }

      return { sidebarWidth, toolbarHeight }
    },
    calculateHoveredThumbnailPosition() {
      const eventCardTop = this.hoveredThumbnail.targetRect?.top || 0
      const gridLeft = this.$parent.$el.getBoundingClientRect()?.left || 0
      const hoveredThumnailbHeight =
        this.$refs.hoveredThumbnail?.getBoundingClientRect()?.height || 0

      if (this.displayPosition === "top") {
        return this.calculateHoveredThumbnailTopPosition({
          eventCardTop,
          hoveredThumnailbHeight,
          gridLeft,
        })
      }

      if (this.displayPosition === "left") {
        return this.calculateHoveredThumbnailLeftPosition({
          eventCardTop,
          hoveredThumnailbHeight,
          gridLeft,
        })
      }

      return {}
    },
    calculateHoveredThumbnailTopPosition({
      eventCardTop,
      hoveredThumnailbHeight,
      gridLeft,
    }) {
      const shouldShowOnBottom =
        hoveredThumnailbHeight > this.hoveredThumbnail.targetRect?.top

      if (shouldShowOnBottom) {
        return this.calculateHoveredThumbnailBottomPosition({
          gridLeft,
        })
      }

      const { sidebarWidth, toolbarHeight } = this.getSidebarAndToolbarSizes()

      return {
        top: `${eventCardTop - hoveredThumnailbHeight - toolbarHeight}px`,
        left: `calc(${gridLeft}px - ${sidebarWidth})`,
      }
    },
    calculateHoveredThumbnailBottomPosition({ gridLeft }) {
      const { sidebarWidth, toolbarHeight } = this.getSidebarAndToolbarSizes()
      const eventCardBottom = this.hoveredThumbnail.targetRect?.bottom || 0

      return {
        top: `${eventCardBottom - toolbarHeight}px`,
        left: `calc(${gridLeft}px - ${sidebarWidth})`,
      }
    },
    calculateHoveredThumbnailLeftPosition({
      hoveredThumnailbHeight,
      eventCardTop,
      gridLeft,
    }) {
      const { sidebarWidth, toolbarHeight } = this.getSidebarAndToolbarSizes()
      const eventCardBottom = this.hoveredThumbnail.targetRect?.bottom || 0
      const hoveredThumnailbWidth =
        this.$refs.hoveredThumbnail?.getBoundingClientRect()?.width || 0

      const shouldShowOnLeftTop =
        hoveredThumnailbHeight >
        window.innerHeight - this.hoveredThumbnail.targetRect?.top

      const leftTop = shouldShowOnLeftTop
        ? eventCardBottom - hoveredThumnailbHeight
        : eventCardTop

      return {
        top: `${leftTop - toolbarHeight}px`,
        left: `calc(${gridLeft}px - ${sidebarWidth} - ${hoveredThumnailbWidth}px)`,
      }
    },
  },
}
</script>

<style lang="scss">
.thumbnail-hover {
  z-index: 99999;
  max-width: 450px;
  min-width: 450px;
  pointer-events: none;
}
</style>
