export default {
  name: "CustomSort",
  data() {
    return {
      sortFields: [],
      sortField: {},
      sortTypes: {
        date(a, b, isDesc, field) {
          const aDate = a[field] && this.$moment(a[field])
          const bDate = b[field] && this.$moment(b[field])
          if (!aDate && !bDate) return 0
          if (!aDate) return 1
          if (!bDate) return -1
          if (isDesc) {
            return aDate.isAfter(bDate) ? -1 : 1
          }

          return aDate.isBefore(bDate) ? -1 : 1
        },
        text(a, b, isDesc, field) {
          if (isDesc) {
            return a && b && a[field] > b[field] ? -1 : 1
          }

          return a && b && a[field] < b[field] ? -1 : 1
        },
        subCompare(a, b, isDesc, path, sortType) {
          path = path?.split(".") || []
          const field = path.pop()
          a = this.getNestedObject(a, path)
          b = this.getNestedObject(b, path)

          return this.sortTypes[sortType].apply(this, [a, b, isDesc, field])
        },
      },
    }
  },
  methods: {
    customSort(items, [sortedColumn], [isDesc]) {
      if (!sortedColumn) {
        return items
      }
      this.sortField = this.sortFields.find(
        (item) => item?.key === sortedColumn
      ) || { key: sortedColumn }
      items.sort((a, b) => {
        if (this.sortField.customSort) {
          return this.sortField.customSort.apply(this, [a, b, isDesc])
        }
        if (this.sortField?.path) {
          return this.sortTypes.subCompare.apply(this, [
            a,
            b,
            isDesc,
            this.sortField?.path,
            this.sortField?.type || "text",
          ])
        }

        return this.sortTypes[this.sortField?.type || "text"].apply(this, [
          a,
          b,
          isDesc,
          this.sortField?.key,
        ])
      })

      return items
    },
    getNestedObject(object, path) {
      return path?.reduce((acc, key) => acc[key] || {}, object || {}) || {}
    },
  },
}
