<template>
  <div ref="container" class="events-heatmap">
    <ECalendarHeatmap
      :items="items"
      :day-rect-size="12"
      class="w-100 px-2"
      :start-date="startDate"
      :end-date="endDate"
      :label-fn="formatLabel"
      :full-width="true"
      :is-disabled-fn="isDateDisabled"
      :highlight-color-fn="getDayHighlightColor"
      :selected-date="formattedSelectedDate"
      @item-selected="handleItemSelection"
    >
      <!-- Custom legend -->
      <template #customLegend>
        <div class="events-heatmap__legend d-flex">
          <!-- More / less -->
          <div class="d-flex align-center">
            <div class="mr-1">Less</div>
            <div class="d-flex">
              <div
                v-for="color in colors"
                :key="color"
                class="d-flex events-heatmap__legend__color"
                :style="getColorLegendStyle(color)"
              ></div>
            </div>
            <div class="ml-1">More</div>
          </div>

          <div class="pl-3 grey--text">|</div>

          <!-- Verified -->
          <div class="events-heatmap__legend d-flex align-center">
            <div class="mr-1">Verified</div>
            <div
              class="d-flex events-heatmap__legend__color"
              :style="getColorLegendStyle(verifiedColor)"
            ></div>
          </div>

          <div class="pl-3 grey--text">|</div>

          <!-- Unverified -->
          <div class="events-heatmap__legend d-flex align-center">
            <div class="mr-1">Unverified</div>
            <div
              class="d-flex events-heatmap__legend__color"
              :style="getColorLegendStyle(unverifiedColor)"
            ></div>
          </div>
        </div>
      </template>
    </ECalendarHeatmap>
  </div>
</template>

<script>
import { useDayVerificationStore } from "@/stores/dayVerification"
import { mapStores } from "pinia"

export default {
  name: "EventsHeatmap",
  props: {
    selectedDate: {
      type: [String, Date],
      default: new Date(),
    },
    items: {
      type: Array,
      default: () => [],
    },
    formatLabel: {
      type: Function,
      default: ({ date, count }) => `${date}, ${count}`,
    },
    isDateDisabled: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      container: null,
      containerWidth: 800,
      colors: ["#ebedf0", "#c0ddf9", "#73b3f3", "#3886e1", "#17459e"],
      verifiedColor: "#4a9100",
      unverifiedColor: "#f18023",
    }
  },
  computed: {
    ...mapStores(useDayVerificationStore),
    startDate() {
      return this.$moment(this.endDate)
        .subtract({ months: Math.floor(this.containerWidth / 75) })
        .format("YYYY-MM-DD")
    },
    endDate() {
      return this.$moment(this.selectedDate)
        .add({ months: this.containerWidth / 150 })
        .format("YYYY-MM-DD")
    },
    formattedSelectedDate() {
      return this.$moment(this.selectedDate).format("YYYY-MM-DD")
    },
  },
  mounted() {
    this.$nextTick(this.onResize)
    this.$setTimeout(this.onResize, 500)
    this.$addEventListener("resize", this.onResize)
  },
  methods: {
    onResize() {
      this.containerWidth = this.$refs.container?.getBoundingClientRect().width
    },
    handleItemSelection(dayItem) {
      const date = dayItem?.date
      if (!date) {
        return
      }
      this.$emit("date-selected", date)
    },
    getDayHighlightColor(date) {
      if (this.dayVerificationStore.isDayVerified(date)) {
        return this.verifiedColor
      } else if (!this.isDateDisabled(date)) {
        return this.unverifiedColor
      }
    },
    getColorLegendStyle(color) {
      return {
        background: color,
      }
    },
  },
}
</script>

<style lang="scss">
.events-heatmap {
  &__legend {
    padding-left: 13px;
    & > * {
      font-size: 11px;
    }
    &__color {
      width: 10px;
      height: 10px;
      border-radius: 2px;
      margin: 0 1.5px;
    }
  }
}
.heatmap-calendar {
  &__day {
    rect {
      fill: #ebedf0;
    }
    &--hovered {
      rect {
        stroke: #5c6d97;
      }
    }
    &--selected {
      rect {
        stroke: #17252d;
      }
    }
  }
}
</style>
